import React from "react";

import {Button, withStyles, Typography, Grid, Container} from "@material-ui/core";

import {Link} from "react-router-dom"
import ArticlePreview from "../../ArticlePreview";


const styles = theme => ({
    root: {
        '&:after': {
            content: '""',
            height: '100px',
            width: "100%",
        }
    },
});

const EditButton = ({article}) => (
    <Button component={Link} to={`/eleven/news/${article.id}/edit`} >
        EDIT
    </Button>    
)

const Status  = ({published, className}) => (
    <Typography type="button" className={className} >
        {published ? "PUBLISHED" : "UNPUBLISHED"}
    </Typography>    
)

// const actions = (article, {actionItems}) => (
//     [
//         <EditButton article={article} key="Edit Announcement" />,
//         <Status published={article.published} className={actionItems} key="PUBLISHED" />
//     ] 
// )

class AnnouncementList extends React.Component{
    componentDidMount(){
        this.props.getAnnouncements()
    }
    render(){
        const {announcements, admin} = this.props;
        const classes = this.props.classes;
        let postArray = announcements.map(post => (
            <Grid item xs={12} key={post.id}>
                <ArticlePreview 
                    link={`/eleven/news/${post.id}`}
                    article={post}
                    author={post.user}
                    actions={
                        (admin) ? 
                            (article, {actionItems}) => (
                                [
                                    <EditButton article={article} key="Edit Announcement" />,
                                ] 
                            )
                        : null
                    }
                />
            </Grid>
        ))
        return (
            <Container fixed >
                <Grid container justify="center" spacing={10}>
                    {postArray}
                </Grid>
            </Container>
        )
    }
}

export default withStyles(styles)(AnnouncementList);